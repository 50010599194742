import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroMini from "../components/heroSmall"

const ServicesPage = () => (
  <Layout>
    <Seo title="Cloud Development" />
    <HeroMini title="Cloud Development"/>
    <main className="section has-text-black">
  <div className="container">
  <div className="columns">
  <div className="column">
    <p className="mb-6">Depend on our full-spectrum of consultation services to provide the ingenuity you need to solve your challenges on time and within budget.</p>
  </div>
  </div>

  {/* <div className="columns">
  <div className="column content">
  <p className="is-size-2">HTML</p>
  </div>
  </div> */}

  <div className="columns">
  <div className="column content has-text-centered">
  
    <p className="is-size-4 fusionfont">Azure Web Apps</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/color/48/000000/azure-1.png"/>
    <p>Azure Web Apps is a cloud based platform for hosting websites. It is a platform as a service which allows publishing Web apps running on multiple frameworks and written in different programming languages, including Microsoft proprietary ones and 3rd party ones.</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Azure Cognitive Search</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/color/48/000000/azure-relay-hybrid-connection.png"/>
    <p>Azure Cognitive Search is the only cloud search service with built-in AI capabilities that enrich all types of information to help you identify and explore relevant content at scale. Use cognitive skills for vision, language, and speech, or use custom machine learning models to uncover insights from all types of content.</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Azure Functions</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/color/48/000000/azure-service-bus--v1.png"/>
    <p>Azure Functions is a serverless solution that allows you to write less code, maintain less infrastructure, and save on costs. Instead of worrying about deploying and maintaining servers, the cloud infrastructure provides all the up-to-date resources needed to keep your applications running.</p>
  </div>
</div>
{/* 
<div className="columns">
  <div className="column content">
  <p className="is-size-2">React</p>
  </div>
  </div> */}


</div>
</main>
  </Layout>
)

export default ServicesPage
